.card {
  &.girlguiding {
    border: 1px solid #4e88c7;
    font-family: Trebuchet, sans-serif;

    h5 {
      color: #c40063;
      font-weight: bold;
      font-style: italic;
    }

    p,
    ul {
      color: #4e88c7;
    }

    ul {
      margin-left: 2em;
      margin-bottom: 0.6em;
      list-style-type: none;
    }

    div {
      &.card-img-top {
        background-image: url(GirlguidingCard/card-background.jpg);
        background-position: center;
        background-size: cover;
        min-height: 16em;
      }
    }

    a {
      &.btn {
        &.btn-outline-secondary {
          background-color: #c40063;
          border: 1px solid #c40063;
          color: #ffffff;

          &:hover {
            background-color: #ffffff;
            border: 1px solid #c40063;
            color: #c40063;
          }

          &:focus {
            box-shadow: 0 0 0 0.25rem rgba(196, 0, 63, 0.5);
          }
        }
      }
    }
  }
}
