.card {
  &.dec {
    border: 1px solid #000066;
    font-family: Trebuchet, sans-serif;
    background-image: url(DECCard/card-background.jpg);
    background-position: center;
    background-size: cover;

    h5 {
      color: #ffffff;
      font-weight: bold;
    }

    p,
    ul {
      color: #ffffff;
    }

    ul {
      margin-left: 2em;
      margin-bottom: 0.6em;
      list-style-type: none;
    }

    a {
      &.btn {
        &.btn-outline-secondary {
          background-color: #000066;
          border: 1px solid #000066;
          color: #ffffff;

          &:hover {
            background-color: #000066;
            border: 1px solid #ffffff;
            color: #ffffff;
          }

          &:focus {
            box-shadow: 0 0 0 0.25rem rgba(196, 0, 63, 0.5);
          }
        }
      }
    }
  }
}
