.mobile-menu {
  display: none;
}
.mobile-menu li a {
  display: block;
  font-size: 1.2em;
  margin: 1.5em;
}

nav {
  /* Anchor will cover the whole item by default */
  /* For some effects it will show as a button */
  /* Individual effects */
  /* Media queries */
}
nav .toggle-mobile-menu {
  display: block;
  width: 100% !important;
  margin: 0 auto;
}
nav ul.menu {
  margin: 0 auto;
}
nav.main-navigation .effect-grid {
  position: relative;
  clear: both;
  margin: 0 auto;
  padding: 1em 0 4em;
  padding-top: 0;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}
nav.main-navigation .effect-grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  background: #544531;
  text-align: center;
  cursor: pointer;
}
nav.main-navigation .effect-grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}
nav.main-navigation .effect-grid figure figcaption {
  padding: 2em;
  color: #544531;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(255, 255, 255, 0.4);
}
nav .effect-grid figure figcaption::before,
nav .effect-grid figure figcaption::after {
  pointer-events: none;
}
nav .effect-grid figure figcaption,
nav .effect-grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
nav .effect-grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}
nav .effect-grid figure h2 {
  font-weight: 300;
}
nav .effect-grid figure h2 span {
  font-weight: 800;
}
nav .effect-grid figure h2,
nav .effect-grid figure p {
  margin: 0;
}
nav .effect-grid figure p {
  font-size: 0.8em;
}
nav figure.effect-goliath {
  background: #ffffff;
}
nav figure.effect-goliath img,
nav figure.effect-goliath h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}
nav figure.effect-goliath img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
nav figure.effect-goliath h2,
nav figure.effect-goliath p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
}
nav figure.effect-goliath p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
  width: 100%;
  background-color: #ffffff;
}
nav figure.effect-goliath p img.give-a-little {
  display: inline-block;
  float: right;
}
nav figure.effect-goliath:hover > img {
  -webkit-transform: translate3d(0, -80px, 0);
  transform: translate3d(0, -80px, 0);
}
nav figure.effect-goliath:hover h2 {
  -webkit-transform: translate3d(0, -90px, 0);
  transform: translate3d(0, -90px, 0);
}
nav figure.effect-goliath:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
@media screen and (max-width: 50em) {
  nav .content {
    padding: 0 10px;
    text-align: center;
  }
  nav .effect-grid figure {
    display: inline-block;
    float: none;
    margin: 10px auto;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  nav .mobile-menu {
    display: none;
  }
  nav .mobile-menu.show {
    display: revert;
  }
}

