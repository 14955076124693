section#contact-message .form-group-to_name {
  display: none;
}
section#contact-message .dd-container {
  position: relative;
}
section#contact-message .dd-container .dd-select {
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
section#contact-message .dd-container .dd-select .dd-selected {
  overflow: hidden;
  display: block;
  padding: 10px;
  font-weight: bold;
  background: #ffffff;
  border: none;
  color: #8f7655;
  line-height: normal;
}
section#contact-message .dd-container .dd-select .dd-selected .dd-selected-text {
  color: #000033;
  font-weight: bold;
  width: auto !important;
}
section#contact-message .dd-container .dd-options {
  border: solid 1px rgb(206.9078947368, 192.7631578947, 174.0921052632);
  border-top: none;
  list-style: none;
  display: none;
  position: absolute;
  z-index: 2000;
  margin: 0;
  padding: 0;
  background: #ffffff;
  overflow: auto;
}
section#contact-message .dd-container .dd-options > li:last-child > .dd-option {
  border-bottom: none;
}
section#contact-message .dd-container .dd-options .dd-option {
  padding: 10px;
  display: block;
  border-bottom: solid 1px rgb(206.9078947368, 192.7631578947, 174.0921052632);
  overflow: hidden;
  text-decoration: none;
  color: #000033;
  cursor: pointer;
  margin-bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
}
section#contact-message .dd-container .dd-options .dd-option.dd-option-selected {
  background: rgb(225.9210526316, 217.3684210526, 206.0789473684);
}
section#contact-message .dd-container .dd-options .dd-option:hover {
  background: rgb(216.4144736842, 205.0657894737, 190.0855263158);
  color: #000033;
}
section#contact-message .dd-container .dd-options .dd-option .dd-option-text {
  width: auto !important;
  margin-bottom: 0.2rem;
}
section#contact-message .dd-container .dd-option-image {
  max-width: 48px !important;
  max-height: 48px !important;
}
section#contact-message .dd-container .dd-option-image,
section#contact-message .dd-container .dd-selected-image {
  vertical-align: middle;
  float: left;
  margin-right: 5px;
  max-width: 36px;
}
section#contact-message .dd-desc {
  color: #8f7655;
  display: block;
  overflow: hidden;
  font-weight: normal;
  line-height: 1.4em;
}
section#contact-message .dd-pointer {
  width: 0;
  height: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -3px;
}
section#contact-message .dd-pointer-down {
  border: solid 5px transparent;
  border-top: solid 5px #000;
}
section#contact-message .dd-pointer-up {
  border: solid 5px transparent !important;
  border-bottom: solid 5px #000 !important;
  margin-top: -8px;
}
section#contact-message .dd-selected-description-truncated {
  text-overflow: ellipsis;
  white-space: nowrap;
}
section#contact-message .dd-image-right {
  float: right;
  margin-right: 15px;
  margin-left: 5px;
}

