section#venues-and-hall-hire div.venue-details {
  margin-bottom: 3em;
}
section#venues-and-hall-hire div.venue-details h3 {
  margin-bottom: 0;
}
section#venues-and-hall-hire div.venue-details .tags .tag {
  background-color: #8f7655;
  color: #ffffff;
  display: inline-block;
  padding: 0.1em 0.7em;
  margin: 0.3em 0.4em 0 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

