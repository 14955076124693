section#safeguarding {
  .centre-header {
    text-align: center;
  }

  .container {
    ul {
      margin-left: 2em;

      li {
        margin-bottom: 1em;
      }
    }
  }
}
