section#transport-links {
  a.bus-service {
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    padding: 0 0.5em;
    color: #c00;
    border: 1px solid #c00;
    background-color: #fff;
    //margin-right: 0.2em;

    &:hover {
      color: #fff;
      border: 1px solid #fff;
      background-color: #c00;
    }
  }
}
