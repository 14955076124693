@import url(https://fonts.googleapis.com/css?family=Rubik+Distressed|Open+Sans:400italic,700italic,400,700);
.parish-profile {
  background-image: url(ParishProfileAndVacancyBlock/bg.jpg);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 1.6em;
  text-shadow: 2px 2px 2px #000000;
  vertical-align: middle;
}
.parish-profile p {
  padding-bottom: 0.8em;
}
.parish-profile a {
  color: #ffcc33;
}

