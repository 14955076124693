@import url(https://fonts.googleapis.com/css?family=Kaushan+Script|Open+Sans:400italic,700italic,400,700);
.card.redbookcart {
  background-image: url(RedBookCartCard/card-background.jpg);
  background-position: center;
  background-size: cover;
  border: 1px solid #ff0000;
  font-family: Trebuchet, sans-serif;
  font-size: 1.1em;
}
.card.redbookcart h5 {
  color: #000000;
  font-weight: bold;
  font-style: italic;
  font-size: 2.7em;
  font-family: "Kaushan Script", Trebuchet, sans-serif;
}
.card.redbookcart h5 span {
  color: #ff0000;
}
.card.redbookcart p {
  color: #000000;
}

