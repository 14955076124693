.card {
  &.cornerstones {
    border: 2px solid #999999;
    font-family: Trebuchet, sans-serif;

    p,
    ul {
      color: #666666;
    }

    ul {
      margin-left: 2em;
      margin-bottom: 0.6em;
      list-style-type: none;
    }

    a {
      &.btn {
        &.btn-outline-secondary {
          background-color: #000066;
          border: 1px solid #000066;
          color: #ffffff;

          &:hover {
            background-color: #000066;
            border: 1px solid #ffffff;
            color: #ffffff;
          }

          &:focus {
            box-shadow: 0 0 0 0.25rem rgba(196, 0, 63, 0.5);
          }
        }
      }
    }
  }
}
