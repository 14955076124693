#news-page .list-item {
  margin-bottom: 2em;
}
#news-page .list-item h3 {
  color: #000033;
  line-height: inherit;
}
#news-page .list-item h3 small {
  margin-left: 0.5em;
  color: #8f7655;
}
#news-page .list-item img.item-thumbnail {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 1em;
}
#news-page .list-item .downloads {
  margin-top: 0.5em;
}
#news-page .list-item .downloads a i {
  margin: 0 0.5em;
}
#news-page .list-item .tags .tag {
  background-color: #8f7655;
  color: #ffffff;
  display: inline-block;
  padding: 0.1em 0.7em;
  margin: 0.3em 0.4em 0 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

