.card.dec {
  border: 1px solid #000066;
  font-family: Trebuchet, sans-serif;
  background-image: url(DECCard/card-background.jpg);
  background-position: center;
  background-size: cover;
}
.card.dec h5 {
  color: #ffffff;
  font-weight: bold;
}
.card.dec p,
.card.dec ul {
  color: #ffffff;
}
.card.dec ul {
  margin-left: 2em;
  margin-bottom: 0.6em;
  list-style-type: none;
}
.card.dec a.btn.btn-outline-secondary {
  background-color: #000066;
  border: 1px solid #000066;
  color: #ffffff;
}
.card.dec a.btn.btn-outline-secondary:hover {
  background-color: #000066;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.card.dec a.btn.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(196, 0, 63, 0.5);
}

