// Color Variables
$accent-color           : #8f7655;
$primary-color          : $accent-color;
$secondary-color        : #000033;

$strong-color           : #544531;
$body-color             : #89837c;
$muted-color            : #000033;
$border-color           : #e5e5e5;
$highlight-color        : #ffffff;
$background-color       : $highlight-color;

$header-color           : $background-color;
$header-secondary-color : $secondary-color;

// Social Media Colours
$facebook-color         : #4267b2;
$twitter-color          : #1da1f2;
