li.menu-item.community figcaption {
  color: #000033 !important;
  background-color: rgba(255, 255, 255, 0) !important;
  text-transform: unset !important;
}
li.menu-item.community figcaption p {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
li.menu-item.community h2 {
  font-size: 1.4em;
  font-weight: bold !important;
  float: left;
  width: 100%;
  z-index: 1;
  color: #fff;
  position: absolute;
  top: 0;
  text-align: left;
  padding: 0.4em;
  text-shadow: 2px 2px 2px #000000;
}

