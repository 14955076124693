@import "../../public/styles/libs/palettes/default";

#welcome-page {
  .welcome-body {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .homepage-list {
    li {
      a {
        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  .point-of-interest {
    background-color: $primary-color;
    color: $background-color;

    ul {
      margin-left: 1.5em;

      li {
        margin-bottom: 0.5em;
      }
    }
  }
}

.intro-quote {
  text-shadow: 1px 1px #33333399;

  &:before,
  &:after {
    text-shadow: none;
  }
}

.twitter-timeline {
  overflow: scroll;
  height: 35em;
}
