section#safeguarding .centre-header {
  text-align: center;
}
section#safeguarding .container ul {
  margin-left: 2em;
}
section#safeguarding .container ul li {
  margin-bottom: 1em;
}

