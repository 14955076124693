section#services-and-worship .service-details li {
  padding: 3em 0;
  border-bottom: 1px solid #8f7655;
}
section#services-and-worship .service-details li:last-child {
  border-bottom: none;
}
section#services-and-worship .service-details li h4 {
  color: #000033;
  margin-bottom: 0.2em;
}
section#services-and-worship .service-details li h5 {
  margin-bottom: 0.7em;
}
section#services-and-worship .service-details li p {
  margin-bottom: 0.6em;
}
section#services-and-worship .service-details li .tags .tag {
  background-color: #8f7655;
  color: #ffffff;
  display: inline-block;
  padding: 0.1em 0.7em;
  margin: 0.3em 0.4em 0 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

