@import "../../public/styles/libs/palettes/default";

section#venues-and-hall-hire {
  div {
    &.venue-details {
      margin-bottom: 3em;

      h3 {
        margin-bottom: 0;
      }

      .tags {
        .tag {
          background-color: $primary-color;
          color: $background-color;
          display: inline-block;
          padding: 0.1em 0.7em;
          margin: 0.3em 0.4em 0 0;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
        }
      }
    }
  }
}
