.childrens-download {
  text-align: center;
  margin-bottom: 2.8em;
}
.childrens-download a {
  background-repeat: no-repeat;
  display: inline-block;
}
.childrens-download a img {
  border: 3px solid;
  margin-bottom: 0.8em;
}
.childrens-download a h3 {
  font-size: 1em;
  display: block;
  margin-bottom: 0.5em;
}

