@import "../../../public/styles/libs/palettes/default";

section#contact-message {
  .form-group-to_name {
    display: none;
  }

  .dd-container {
    position: relative;

    .dd-select {
      position: relative;
      cursor: pointer;

      margin: 0;
      padding: 0;

      display: block;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .dd-selected {
        overflow: hidden;
        display: block;
        padding: 10px;
        font-weight: bold;

        background: $background-color;
        border: none;
        color: $primary-color;
        line-height: normal;

        .dd-selected-text {
          color: $secondary-color;
          font-weight: bold;
          width: auto !important;
        }
      }
    }

    .dd-options {
      border: solid 1px lighten($primary-color, 30%);
      border-top: none;
      list-style: none;
      display: none;
      position: absolute;
      z-index: 2000;
      margin: 0;
      padding: 0;
      background: $background-color;
      overflow: auto;

      & > li:last-child > .dd-option {
        border-bottom: none;
      }

      .dd-option {
        padding: 10px;
        display: block;
        border-bottom: solid 1px lighten($primary-color, 30%);
        overflow: hidden;
        text-decoration: none;
        color: $secondary-color;
        cursor: pointer;
        margin-bottom: 0;
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;

        &.dd-option-selected {
          background: lighten($primary-color, 40%);
        }

        &:hover {
          background: lighten($primary-color, 35%);
          color: $secondary-color;
        }

        .dd-option-text {
          width: auto !important;
          margin-bottom: 0.2rem;
        }
      }
    }

    .dd-option-image {
      max-width: 48px !important;
      max-height: 48px !important;
    }

    .dd-option-image,
    .dd-selected-image {
      vertical-align: middle;
      float: left;
      margin-right: 5px;
      max-width: 36px;
    }
  }

  .dd-desc {
    color: $primary-color;
    display: block;
    overflow: hidden;
    font-weight: normal;
    line-height: 1.4em;
  }

  .dd-pointer {
    width: 0;
    height: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
  }

  .dd-pointer-down {
    border: solid 5px transparent;
    border-top: solid 5px #000;
  }

  .dd-pointer-up {
    border: solid 5px transparent !important;
    border-bottom: solid 5px #000 !important;
    margin-top: -8px;
  }

  .dd-selected-description-truncated {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dd-image-right {
    float: right;
    margin-right: 15px;
    margin-left: 5px;
  }
}
