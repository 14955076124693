@import "../../../../public/styles/libs/palettes/default";

li.menu-item.about-us {
  figcaption {
    color: $secondary-color !important;
    background-color: #ffffff00 !important;
    text-transform: unset !important;

    p {
      background-color: #ffffffcc !important;
    }
  }

  h2 {
    font-size: 1.4em;
    font-weight: bold !important;
    float: left;
    width: 100%;
    z-index: 1;
    color: #fff;
    position: absolute;
    top: 0;
    text-align: left;
    padding: 0.4em;
    text-shadow: 2px 2px 2px #000000;
  }
}
