#groups-and-clubs .effect-grid {
  padding-bottom: 0;
}
#groups-and-clubs .effect-grid figure.effect-goliath p {
  background-color: rgba(255, 255, 255, 0.6);
}
#groups-and-clubs .effect-grid figure figcaption {
  background-color: rgba(255, 255, 255, 0);
}
#groups-and-clubs ul.groups-adults img {
  height: 64px;
  width: 76px;
}
#groups-and-clubs ul.groups-adults .group-header {
  display: block;
  float: left;
  width: 100%;
}
#groups-and-clubs ul.groups-adults .group-header h6 {
  display: inline-block;
  float: left;
  padding-right: 0.5em;
  margin: 0;
  font-weight: bold;
}
#groups-and-clubs ul.groups-adults .group-header .age-group {
  display: inline-block;
  float: left;
  margin-bottom: 2px;
  line-height: initial;
  color: #000033;
}

