section#children ul#further-resources {
  list-style: none;
  text-align: center;
  width: 100%;
  float: left;
  margin-bottom: 3em;
}
section#children ul#further-resources li {
  display: inline-block;
}

