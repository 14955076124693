section#privacy-policy {
  .centre-header {
    text-align: center;
  }

  .container {
    ol,
    ul {
      margin-left: 2em;

      li {
        margin-bottom: 1em;
      }
    }

    address {
      margin-left: 2em;

      a {
        margin-top: 1em;
        display: inline-block;
      }
    }
  }
}
