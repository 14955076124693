#welcome-page .welcome-body {
  margin-bottom: 0;
  padding-bottom: 0;
}
#welcome-page .homepage-list li a img {
  width: 48px;
  height: 48px;
}
#welcome-page .point-of-interest {
  background-color: #8f7655;
  color: #ffffff;
}
#welcome-page .point-of-interest ul {
  margin-left: 1.5em;
}
#welcome-page .point-of-interest ul li {
  margin-bottom: 0.5em;
}

.intro-quote {
  text-shadow: 1px 1px rgba(51, 51, 51, 0.6);
}
.intro-quote:before, .intro-quote:after {
  text-shadow: none;
}

.twitter-timeline {
  overflow: scroll;
  height: 35em;
}

