@import "../../public/styles/libs/palettes/default";

#news-page {
  .list-item {
    margin-bottom: 2em;

    h3 {
      color: $secondary-color;
      line-height: inherit;

      small {
        margin-left: 0.5em;
        color: $primary-color;
      }
    }
    img.item-thumbnail {
      width: 32px;
      height: 32px;
      float: left;
      margin-right: 1em;
    }

    .downloads {
      margin-top: 0.5em;

      a {
        i {
          margin: 0 0.5em;
        }
      }
    }

    .tags {
      .tag {
        background-color: $primary-color;
        color: $background-color;
        display: inline-block;
        padding: 0.1em 0.7em;
        margin: 0.3em 0.4em 0 0;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
      }
    }
  }
}
