li.menu-item.donations {
  .passive-giving {
    li {
      display: block;
      margin: 2em 0.6em;

      a {
        display: inline-block;
        font-size: revert;
      }
    }
  }
}
