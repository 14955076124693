@import "../../public/styles/libs/palettes/default";

section#services-and-worship {
  .service-details {
    li {
      padding: 3em 0;
      border-bottom: 1px solid $primary-color;

      &:last-child {
        border-bottom: none;
      }

      h4 {
        color: $secondary-color;
        margin-bottom: 0.2em;
      }

      h5 {
        margin-bottom: 0.7em;
      }

      p {
        margin-bottom: 0.6em;
      }

      .tags {
        .tag {
          background-color: $primary-color;
          color: $background-color;
          display: inline-block;
          padding: 0.1em 0.7em;
          margin: 0.3em 0.4em 0 0;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
        }
      }
    }
  }
}
