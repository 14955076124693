@import "../../../../public/styles/libs/palettes/default";

#groups-and-clubs {
  .effect-grid {
    padding-bottom: 0;

    figure {
      &.effect-goliath {
        p {
          background-color: #ffffff99;
        }
      }
      figcaption {
        background-color: #ffffff00;
      }
    }
  }

  ul {
    &.groups-adults {
      img {
        height: 64px;
        width: 76px;
      }

      .group-header {
        display: block;
        float: left;
        width: 100%;

        h6 {
          display: inline-block;
          float: left;
          padding-right: 0.5em;
          margin: 0;
          font-weight: bold;
        }

        .age-group {
          display: inline-block;
          float: left;
          margin-bottom: 2px;
          line-height: initial;
          color: $secondary-color;
        }
      }
    }
  }
}
