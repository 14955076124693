section#facilities-list {
  width: 100%;

  ul {
    margin-left: 0;
    list-style: none;
    padding: 0;

    li {
      i {
        display: inline-block;
        margin-right: 0.3em;
      }
    }
  }
}
