section#privacy-policy .centre-header {
  text-align: center;
}
section#privacy-policy .container ol,
section#privacy-policy .container ul {
  margin-left: 2em;
}
section#privacy-policy .container ol li,
section#privacy-policy .container ul li {
  margin-bottom: 1em;
}
section#privacy-policy .container address {
  margin-left: 2em;
}
section#privacy-policy .container address a {
  margin-top: 1em;
  display: inline-block;
}

