section#passive-giving ul {
  list-style: none;
  margin-bottom: 1rem;
  text-align: center;
}
section#passive-giving ul li {
  display: inline-block;
  margin: 1em 3em;
}

