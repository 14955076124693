.card.girlguiding {
  border: 1px solid #4e88c7;
  font-family: Trebuchet, sans-serif;
}
.card.girlguiding h5 {
  color: #c40063;
  font-weight: bold;
  font-style: italic;
}
.card.girlguiding p,
.card.girlguiding ul {
  color: #4e88c7;
}
.card.girlguiding ul {
  margin-left: 2em;
  margin-bottom: 0.6em;
  list-style-type: none;
}
.card.girlguiding div.card-img-top {
  background-image: url(GirlguidingCard/card-background.jpg);
  background-position: center;
  background-size: cover;
  min-height: 16em;
}
.card.girlguiding a.btn.btn-outline-secondary {
  background-color: #c40063;
  border: 1px solid #c40063;
  color: #ffffff;
}
.card.girlguiding a.btn.btn-outline-secondary:hover {
  background-color: #ffffff;
  border: 1px solid #c40063;
  color: #c40063;
}
.card.girlguiding a.btn.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(196, 0, 63, 0.5);
}

