section#passive-giving {
  ul {
    list-style: none;
    margin-bottom: 1rem;
    text-align: center;

    li {
      display: inline-block;
      margin: 1em 3em;
    }
  }
}
