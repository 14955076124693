.card.cornerstones {
  border: 2px solid #999999;
  font-family: Trebuchet, sans-serif;
}
.card.cornerstones p,
.card.cornerstones ul {
  color: #666666;
}
.card.cornerstones ul {
  margin-left: 2em;
  margin-bottom: 0.6em;
  list-style-type: none;
}
.card.cornerstones a.btn.btn-outline-secondary {
  background-color: #000066;
  border: 1px solid #000066;
  color: #ffffff;
}
.card.cornerstones a.btn.btn-outline-secondary:hover {
  background-color: #000066;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.card.cornerstones a.btn.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(196, 0, 63, 0.5);
}

