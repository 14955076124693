section#facilities-list {
  width: 100%;
}
section#facilities-list ul {
  margin-left: 0;
  list-style: none;
  padding: 0;
}
section#facilities-list ul li i {
  display: inline-block;
  margin-right: 0.3em;
}

