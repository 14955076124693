// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Rubik+Distressed|Open+Sans:400italic,700italic,400,700);

$header-font: "Rubik Distressed", "Novecento Sans", "Raleway", "Noto Sans", "Open Sans", sans-serif;

.parish-profile {
  background-image: url(ParishProfileAndVacancyBlock/bg.jpg);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 1.6em;
  text-shadow: 2px 2px 2px #000000;
  vertical-align: middle;

  p {
    padding-bottom: 0.8em;
  }

  a {
    color:  #ffcc33;
  }
}
