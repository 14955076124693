@import "../../../public/styles/libs/palettes/default";

.mobile-menu {
  display: none;

  li {
    a {
      display: block;
      font-size: 1.2em;
      margin: 1.5em;
    }
  }
}

nav {
  .toggle-mobile-menu {
    display: block;
    width: 100% !important;
    margin: 0 auto;
  }

  ul.menu {
    margin: 0 auto;
  }

  &.main-navigation {
    .effect-grid {
      position: relative;
      clear: both;
      margin: 0 auto;
      padding: 1em 0 4em;
      padding-top: 0;
      max-width: 1000px;
      list-style: none;
      text-align: center;

      figure {
        position: relative;
        float: left;
        overflow: hidden;
        background: $strong-color;
        text-align: center;
        cursor: pointer;

        img {
          position: relative;
          display: block;
          min-height: 100%;
          max-width: 100%;
          opacity: 0.8;
        }

        figcaption {
          padding: 2em;
          color: $strong-color;
          text-transform: uppercase;
          font-size: 1.25em;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          background-color: #ffffff66;
        }
      }
    }
  }

  .effect-grid figure figcaption::before,
  .effect-grid figure figcaption::after {
    pointer-events: none;
  }

  .effect-grid figure figcaption,
  .effect-grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* Anchor will cover the whole item by default */
  /* For some effects it will show as a button */
  .effect-grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
  }

  .effect-grid figure h2 {
    font-weight: 300;
  }

  .effect-grid figure h2 span {
    font-weight: 800;
  }

  .effect-grid figure h2,
  .effect-grid figure p {
    margin: 0;
  }

  .effect-grid figure p {
    font-size: 0.8em;
  }

  /* Individual effects */

  figure.effect-goliath {
    background: $background-color;
  }

  figure.effect-goliath img,
  figure.effect-goliath h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
  }

  figure.effect-goliath img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  figure.effect-goliath h2,
  figure.effect-goliath p {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
  }

  figure.effect-goliath p {
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
    width: 100%;
    background-color: $background-color;

    img.give-a-little {
      display: inline-block;
      float: right;
    }
  }

  figure.effect-goliath:hover > img {
    -webkit-transform: translate3d(0, -80px, 0);
    transform: translate3d(0, -80px, 0);
  }

  figure.effect-goliath:hover h2 {
    -webkit-transform: translate3d(0, -90px, 0);
    transform: translate3d(0, -90px, 0);
  }

  figure.effect-goliath:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  /* Media queries */
  @media screen and (max-width: 50em) {
    .content {
      padding: 0 10px;
      text-align: center;
    }
    .effect-grid figure {
      display: inline-block;
      float: none;
      margin: 10px auto;
      width: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    .mobile-menu {
      display: none;
      &.show {
        display: revert;
      }
    }
  }
}
